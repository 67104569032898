import React from "react";
import { FaLocationArrow } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import Ap from "../../assets/AP.jpeg";
import { CgProfile } from "react-icons/cg";
import whatsapp from "../../assets/whatsapp.png";
import instagram from "../../assets/instagram.png";

const ContactUs = () => {
  // const handlePhoneNumberClick1 = ({ phoneNumber = 7373903333 }) => {
  //   console.log(phoneNumber);
  //   window.open(`tel:${phoneNumber}`);
  // };
  const handlePhoneNumberClick2 = ({ phoneNumber = 6382089083 }) => {
    console.log(phoneNumber);
    window.open(`tel:${phoneNumber}`);
  };
  const handlePhoneNumberClick3 = ({ phoneNumber = 9952026284 }) => {
    console.log(phoneNumber);
    window.open(`tel:${phoneNumber}`);
  };
  return (
    <div
      id="contact"
      className=" w-full min-h-screen pt-28 p-2 flex items-center"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-2">
        <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
          <div className="space-y-3">
            <h2 className="text-4xl font-bold text-[#239907] mb-4">
              Contact Us
            </h2>

            <p className="text-lg text-gray-700 mb-8">
              If you have any questions or would like to schedule a consultation
              with one of our real estate experts, please don't hesitate to
              contact us
            </p>
            <div className="mb-4">
              <img src={Ap} className="h-36 w-28" />
            </div>
            <ul className="text-lg text-gray-700">
              <li className="flex items-center mb-4">
                <CgProfile className="mr-2 text-[#239907]" size={23} />
                K. Packiamani, Proprietor
              </li>
              <li className="flex items-center mb-4">
                <FaLocationArrow className="mr-2 text-[#519342]" size={23} />
                Punniya Bhoomi Nagar,
                <br /> Panagudi Town Panchayat, Tirunelveli - 627109
              </li>
              <li className="flex items-center">
                <FaPhoneAlt className="mr-2  text-[#239907]" size={23} />
                <span className="space-y-3">
                  <span
                    onClick={handlePhoneNumberClick3}
                    className="cursor-pointer"
                  >
                    9952026284
                  </span>{" "}
                  <br />
                  <span
                    onClick={handlePhoneNumberClick2}
                    className="cursor-pointer"
                  >
                    6382089083
                  </span>{" "}
                  <br />
                  {/* <span
                    onClick={handlePhoneNumberClick1}
                    className="cursor-pointer"
                  >
                    7373903333
                  </span> */}
                </span>
              </li>
              <div>
                <div className="text-2xl font-bold text-[#239907] my-2">
                  Social Media
                </div>
                <div className="flex items-center gap-10 px-7">
                  <div>
                    <a
                      href="https://api.whatsapp.com/send?phone=9952026284"
                      target="_blank"
                    >
                      <img src={whatsapp} alt="Social Media" className="h-7" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/aadhan_promoters_/?igsh=NXhtY3ZlOXByeHVw"
                      target="_blank"
                    >
                      <img src={instagram} alt="Social Media" className="h-7" />
                    </a>
                  </div>
                  {/* <div></div> */}
                </div>
              </div>
            </ul>
            <div className="space-y-2">
              <div>
                <div className="text-[#519342] text-2xl font-bold">
                  Office Address:
                </div>
              </div>
              <div className="flex">
                <FaLocationArrow className="m-2 text-[#519342]" size={23} />
                <div className=" text-lg text-gray-700">
                  Sun Moon Complex, Shop no: 7 & 8 <br />
                  Tirunelveli - kanyakumari four way, <br />
                  Near punniyavalanpuram,
                  <br /> Panagudi. 627109.
                </div>
              </div>
            </div>
            {/* <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1395.8349722694759!2d77.57325521964086!3d8.30105684862314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTgnMDQuNSJOIDc3wrAzNCcyNi4wIkU!5e0!3m2!1sen!2sin!4v1711341243205!5m2!1sen!2sin"
                width="600"
                height="450"
                // style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-lg font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="given-name"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#c9e6bf] focus:border-[#c9e6bf] border border-[#c9e6bf] rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#c9e6bf] focus:border-[#c9e6bf] border border-[#c9e6bf] rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-lg font-medium text-gray-700"
                >
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#c9e6bf] focus:border-[#c9e6bf] border border-[#c9e6bf] rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-lg font-medium text-gray-700"
                >
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-[#c9e6bf] focus:border-[#c9e6bf] border border-[#c9e6bf] rounded-md"
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-1 px-6 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-[#239907] hover:bg-[#67b455] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c9e6bf]"
                >
                  Send Message
                </button>
              </div>
            </form>
            {/* <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1395.8349722694759!2d77.57325521964086!3d8.30105684862314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTgnMDQuNSJOIDc3wrAzNCcyNi4wIkU!5e0!3m2!1sen!2sin!4v1711341243205!5m2!1sen!2sin"
                // width="600"
                // height="350"
                // style="border:0;"
                className="w-full"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}
          </div>
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1395.8349722694759!2d77.57325521964086!3d8.30105684862314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTgnMDQuNSJOIDc3wrAzNCcyNi4wIkU!5e0!3m2!1sen!2sin!4v1711341243205!5m2!1sen!2sin"
            // width="600"
            // height="350"
            // style="border:0;"
            className="w-full h-60"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
