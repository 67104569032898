import React from "react";
import plotPattern from "../../assets/File/Road pattern drawing.jpg";
import roadPattern from "../../assets/File/DTCP LAYOUT.jpg";
import DTCPCent from "../../assets/File/DTCP LAYOUT CENTS_page-0001.jpg";
import Home1 from "../../assets/Plots1.jpg";
import DTCP from "../../assets/DTCP.png";
import "../../index.css";
const Features = () => {
  const handlePhoneNumberClick1 = ({ phoneNumber = 9952026284 }) => {
    console.log(phoneNumber);
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <div
      id="feature"
      className="w-full pt-28 border-b-2 border-[#c9e3bf] p-2 flex items-center bg-cover blur-bg "
    // style={{ backgroundImage: `url(${Home1})`,backdropFilter: "blur(20px)" }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-4xl font-bold text-[#239907] mb-6 text-left">
          On Going <span className="text-[#239907]">Project</span>
        </h2>

        <div className="grid md:grid-cols-2 gap-3 mt-2">
          <div className="border-2 border-[#239907]">
            <div className="font-bold text-[#239907] text-2xl bg-white">
              Road View
            </div>
            {/* <a
              href="../../assets/File/DTCP_Layout.pdf"
              download="DTCP LAYOUT.pdf"> */}
              <img
                src={plotPattern}
                className="w-full md:h-[380px]"
              />
            {/* </a> */}
          </div>
          <div className="border-2 border-[#239907]">
            <div className="font-bold text-[#239907] text-2xl bg-white">
              Plot View
            </div>
            <img
              src={roadPattern}
              // onClick={handleImageClick2}
              className="w-full md:h-[380px]"
            />
          </div>
          <div className="border-2 border-[#519342] col-span-2">
            <div className="font-bold text-[#519342] text-2xl bg-white">
              Plot No & Cents
            </div>
            <img
              src={DTCPCent}
              // onClick={handleImageClick2}
              className="w-full h-[780px]"
            />
          </div>
        </div>

        <div className="md:text-2xl text-xl mt-3 font-bold space-y-2 bg-white">
          <div className="md:flex">
            <div className="md:w-[70%]">
              <div>
                Plot in
                <span className="text-[#239907] uppercase "> Panagudi</span>
              </div>
              <span className="md:text-2xl text- xl font-bold ">
                Tirunelveli - Kanyakumari - NH-44
              </span>
            </div>
            <div className="flex justify-center items-center">
              <img src={DTCP} className="h-20" />
            </div>
          </div>
          <div className="bg-[#239907] p-2">
            <p className=" text-white md:text-4xl text-3xl py-2 border-2 border-[#c9e3bf]">
              14 Acres - 300 Plots / 3 & 5 Cents
            </p>
          </div>
        </div>
        <div className="py-2 flex justify-center items-center">
          <buttom
            className="px-5 py-2 bg-[#239907] text-white text-xl font-semibold rounded-md cursor-pointer"
            onClick={handlePhoneNumberClick1}
          >
            Click To Start Saving
          </buttom>
        </div>
      </div>
    </div>
  );
};

export default Features;
