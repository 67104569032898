import React from "react";
import Home1 from "../../assets/Home1.jpg";
import aadhanPromoters from "../../assets/Aadhan Promoters.jpg";
import "./HOme.css";
import { TiTick } from "react-icons/ti";
import HomePageImg from '../../assets/APPromotersLogo-removebg-preview1.png'
// import '../'
const HomePage = () => {
  const handlePhoneNumberClick3 = ({ phoneNumber = 9952026284 }) => {
    console.log(phoneNumber);
    window.open(`tel:${phoneNumber}`);
  };
  return (
    <div
      id="home"
      className="w-full min-h-screen bg-cover space-y-5 relative"
      style={{ backgroundImage: `url(${Home1})` }}
    >
      <div className="relative w-[80%] h-[70%] m-auto left-0 right-0 md:top-50 top-24 bottom-0 py-20 space-y-2">
        <div className="md:text-6xl text-3xl flex items-center font-bold py-2">
          <img src={HomePageImg} className="h-28"/>
          <span className="my-custom-font text-[#239907] tracking-wider">AADHAN <span className="md:text-5xl tracking-normal">Promoters</span></span>
        </div>

        <div className="bg-[#c9e3bf] text-[#239907] w-auto inline-block p-5 space-y-3">
          <p className="text-3xl font-semibold">Ready To Build Villa Plots</p>
          <p>Call Us for The Best Deal...</p>
          <p className="font-bold  flex items-center">
            <span> DTCP Approved </span>
            <TiTick size={25} />
          </p>
          <p className="font-semibold">
            Golden Opportunity for Investment in Plots at Panagudi, Tirunelveli
          </p>
          <div className="py-2">
            <buttom
              className="px-3 py-2  bg-white hover:bg-[#239907] hover:text-white text-xl font-semibold rounded-md cursor-pointer "
              onClick={handlePhoneNumberClick3}
            >
              Click To Start Saving
            </buttom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
