import React from "react";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="bg-[#c9e3bf]">
      <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 ">
          <div className="col-span-2 md:px-10">
            <img src="Logo_BG1.png" className="h-36" />
          </div>
          {/* <div className="col-span-1">
            <h3 className=" text-lg font-medium mb-4">About Us</h3>
            <p className=" mb-4">
              Real Estate Company is a leading provider of real estate services,
              with a focus on delivering exceptional value to our clients. Our
              team of experts has deep industry knowledge and a proven track
              record of success.
            </p>
          </div> */}
          <div className="col-span-1 text-lg">
            {/* <h3 className=" text-lg font-medium mb-4">Aadhan Promoters</h3> */}
            <ul className="">
              <li className="mb-2">
                <Link to="about" smooth={true} duration={500}>
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="sale" smooth={true} duration={500}>
                  Completed Projects
                </Link>
              </li>
              <li className="mb-2">
                <Link to="feature" smooth={true} duration={500}>
                  On Sale
                </Link>
              </li>
              <li className="mb-2">
                <Link to="contact" smooth={true} duration={500}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className=" flex justify-center">
          <p className="text-base ">
            &copy; {new Date().getFullYear()} Real Estate Company. All rights
            reserved.
          </p>
        </div> */}
      </div>
    </footer>
  );
};
export default Footer;
