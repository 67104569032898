import React, { useState, useEffect } from "react";

import { Link } from "react-scroll";

import "../index.css";
// import logo from "../assets/AP Finalout-02.png";
import logo from "../assets/APPromotersLogo.png";
import { TiThMenu } from "react-icons/ti";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 33) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-7xl mx-auto fixed top-0 z-50 w-full flex justify-between items-center px-4 text-black ${
        isScrolled ? "bg-[#239907]" : "bg-[#239907]"
      }`}
    >
      <div className=" cursor-pointer inline-flex items-center">
        <Link to="home" smooth={true} duration={500}>
          <img src={logo} className="md:h-[6rem] h-[5rem]" />
        </Link>
      </div>
      <div className="md:hidden" onClick={handleMenuClick}>
        {showMenu ? (
          <IoClose size={25} className="text-white" />
        ) : (
          <TiThMenu size={25} className="text-white" />
        )}
      </div>
      <ul className="hidden md:flex gap-10 font-semibold text-white">
        <li>
          <Link
            to="home"
            className="cursor-pointer"
            smooth={true}
            duration={500}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="feature"
            className="cursor-pointer"
            smooth={true}
            duration={500}
          >
            On Sale
          </Link>
        </li>
        <li>
          <Link
            to="sale"
            className="cursor-pointer"
            smooth={true}
            duration={500}
          >
            Completed Projects
          </Link>
        </li>
        <li>
          <Link
            to="about"
            className="cursor-pointer"
            smooth={true}
            duration={500}
          >
            About Us
          </Link>
        </li>
      </ul>
      <div className="hidden md:flex">
        <button className="px-4 py-2 bg-[#c9e3bf] font-semibold rounded-md hover:bg-[#] hover:border-2 hover:text-[#239907] mx-2">
          <Link to="contact" smooth={true} duration={500}>
            Contact Us
          </Link>
        </button>
      </div>
      <div
        className={`${
          showMenu ? "flex" : "hidden"
        } md:hidden flex flex-col bg-[#239907] text-white w-full absolute top-16 left-0 z-10`}
      >
        <Link
          to="home"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          Home
        </Link>
        {/* <Link
          to="about"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          About Us
        </Link> */}
        <Link
          to="about"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          About Us
        </Link>
        <Link
          to="feature"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          On Sale
        </Link>
        <Link
          to="sale"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          Completed Projects
        </Link>
        {/* <Link
          to="demo"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          Demo
        </Link> */}
        {/* <Link
          to="client"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          Our Clients
        </Link> */}
        <Link
          to="contact"
          smooth={true}
          duration={500}
          className="p-4 hover:bg-gray-700 cursor-pointer"
          onClick={handleMenuClick}
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
