import React from "react";
// import PropertyImage1 from "../../assets/6.png";
// import PropertyImage2 from "../../assets/7.png";
// import PropertyImage3 from "../../assets/8.png";
import Imagee from "../../assets/Project1.webp";
import Imageee from "../../assets/Project2.jpg";
import Imageeee from "../../assets/Project3.jpg";
import Imageeeee from "../../assets/Project4.jpg";

const SaleProperties = () => {
  const handlePhoneNumberClick1 = ({ phoneNumber = 9952026284 }) => {
    console.log(phoneNumber);
    window.open(`tel:${phoneNumber}`);
  };
  return (
    <div id="sale" className="w-full min-h-screen p-2 pt-28 flex items-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-4xl font-bold text-[#239907] mb-6">
          Completed <span className="text-[#239907]">Projects</span>
        </h2>

        {/* <h2 className="text-4xl font-bold text-gray-800 mb-6">
          <span className="text-amber-400">Completed Projects</span>
        </h2> */}
        {/* <p className="text-lg text-gray-700 mb-8">
        We offer many services for our clients, Here are some of our services:
      </p> */}
        {/* 1 */}
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden relative">
                <img
                  className="w-full h-64 object-cover object-center"
                  src={Imagee}
                  alt="Service 1"
                />
                <button className="absolute bottom-0 font-semibold text-lg px-4 py-2 bg-[#239907] text-white rounded-md hover:bg-amber-700">
                  Kanyakumari
                </button>
              </div>
            </div>

            {/* 2 */}
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden relative">
                <img
                  className="w-full h-64 object-cover object-center"
                  src={Imageee}
                  alt="Service 2"
                />
                <button className="absolute bottom-0 font-semibold text-lg px-4 py-2 bg-[#239907] text-white rounded-md hover:bg-amber-700">
                  Agestheswaram
                </button>
              </div>
            </div>

            {/* 3 */}
            <div className="w-full md:w-1/2 px-4 mb-8 relative">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden relative">
                <img
                  className="w-full h-64 object-cover object-center"
                  src={Imageeee}
                  alt="Service 2"
                />
                <button className="absolute bottom-0 font-semibold text-lg px-4 py-2 bg-[#239907] text-white rounded-md hover:bg-amber-700">
                  Nagercoil
                </button>
              </div>
            </div>

            {/* 4 */}
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden relative">
                <img
                  className="w-full h-64 object-cover object-center"
                  src={Imageeeee}
                  alt="Service 3"
                />
                <button className="absolute bottom-0 font-semibold text-lg px-4 py-2 bg-[#239907] text-white rounded-md hover:bg-amber-700">
                  Kottaram
                </button>
              </div>
            </div>
          </div>
          <div className="py-2 flex justify-center items-center">
            <buttom
              className="px-3 py-2 bg-[#239907] text-white text-xl font-semibold rounded-md cursor-pointer"
              onClick={handlePhoneNumberClick1}
            >
              Get Your Plot Soon
            </buttom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleProperties;
